import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "@app/services/global.service";
import {API_URL} from '@environments/environment';
import {SportFacilityModel} from "@app/models/sport-facility.model";
import {PlaceTypeModel} from "@app/models/place-type.model";
import {SportFacilityCategoryModel} from "@app/models/sport-facility-category.model";

@Injectable({providedIn: 'root'})

export class SportFacilityService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  getBaseSportFacility(query ?: string, sort?, group?, limit?, page?): Observable<{
    elenco: SportFacilityModel[],
    totale: number
  }> {
    return this.http.get<any>(API_URL + '/sportfacility', this.globalService.setTokenAndParams({
      query,
      sort,
      group,
      limit,
      page
    })).pipe(map((data: { elenco: SportFacilityModel[], totale: number }) => {
      return data;
    }));
  }

  getAllSportFacilitySearchByName(name: string, limit: number): Observable<any> {
    return this.http.get(API_URL + '/sportfacility?limit=' + limit + '&page=1&query=' + name).pipe(map((SportFacilityArray: SportFacilityModel[]) => {
      return SportFacilityArray['elenco'];
    }));
  }

  /*  getSportFacilityData(sportFacilityId): Observable<any> {
      return this.http.get(API_URL + '/sportfacility/' + sportFacilityId).pipe(map(data => {
        return data;
      }));
    }*/

  getSportFacilityService(id): Observable<any> {
    return this.http.get(API_URL + '/service', this.globalService.getParams({
      id
    })).pipe(map(data => {
      return data;
    }));
  }

  getMySportFacility(): Observable<SportFacilityModel[]> {
    return this.http.get<any>(API_URL + '/mysportfacility', this.globalService.setToken()).pipe(map((mySportFacility: SportFacilityModel[]) => {
      return mySportFacility;
    }));
  }

  /*getSportFacilityBySportClub() {
    return this.http.get<any>(API_URL + '/get_sport_facility_by_operator/' + id, this.globalService.setToken()).pipe(map((sportFacilityArray: SportFacilityModel[]) => {
      return sportFacilityArray;
    }));
  }*/

  saveSportFacility(body, id): Observable<any> {
    if (id > 0) {
      return this.http.put<any>(API_URL + '/sportfacility/' + id, {body}, this.globalService.setToken()).pipe(map(res => {
        return res;
      }));
    } else {
      return this.http.post<any>(API_URL + '/sportfacility/' + id, {body}, this.globalService.setToken()).pipe(map(res => {
        return res;
      }));
    }
  }

  getSportFacility(id): Observable<SportFacilityModel> {
    return this.http.get<any>(API_URL + '/sportfacility/' + id).pipe(map((res: SportFacilityModel) => {
      return res;
    }));
  }

  getSportFacilityString(id): Observable<SportFacilityModel> {
    return this.http.get<any>(API_URL + '/sportfacility/' + id).pipe(map((res: SportFacilityModel) => {
      return res;
    }));
  }

  saveSportFacilityService(body, id): Observable<any> {
    return this.http.post<any>(API_URL + '/sportfacility_service/' + id, {
      body
    }, this.globalService.setToken()).pipe(map(res => {
      return res;
    }));
  }

  getSportFacilityBySportClub(id: number): Observable<any> {
    return this.http.get(API_URL + '/get_sport_facility_by_sport_club/' + id).pipe(map(data => {
      return data;
    }));
  }

  getAllSportFacilityCategory(): Observable<SportFacilityCategoryModel[]> {
    return this.http.get(API_URL + '/sport_facility_category', this.globalService.setToken()).pipe(map(data => {
      return data['elenco'];
    }));
  }

  getAllPlaceType(): Observable<PlaceTypeModel[]> {
    return this.http.get(API_URL + '/place_type', this.globalService.setToken()).pipe(map(data => {
      return data['elenco'];
    }));
  }

  getSportFacilityBySlug(slug: string): Observable<number> {
    return this.http.get(API_URL + '/sport_facility_by_slug/' + slug).pipe(map((data: number) => {
      return data;
    }));
  }

  /*getSportFacilityImage(size, section, id): Observable<any> {
    return this.http.get(API_URL + '/get_image', this.globalService.getParams({
      id, size, section
    })).pipe(map(data => {
      return data
    }));
  }

  getFederation(size, section, id): Observable<any> {
    return this.http.get(API_URL + '/get_image', this.globalService.getParams({
      size, section, id
    })).pipe(map(data => {
      return data;
    }));
  }*/

  /*
    https://ucarecdn.com/8380642d-27c8-4807-b96b-8a6d040d00a5/Senzatitolo31.svg
  */

  /*getSportBySportFacility(id, limit): Observable<any> {
    return this.http.get(API_URL + '/get_sport_by_sport_club', this.globalService.getParams({
      id,
      limit
    })).pipe(map(data => {
      return data;
    }));
  }*/

  /*getSportBySportFacility(user_lat, user_long, radius, sort, sport_facility, sport_club): Observable<any> {
    return this.http.get(API_URL + '/get_sport_by_sport_club', this.globalService.getParams({
      user_lat,
      user_long,
      radius,
      sort,
      sport_facility,
      sport_club
    })).pipe(map(data => {
      return data;
    }));
  }

  getSportFacilityBySportFacility(id): Observable<any> {
    return this.http.get(API_URL + '/get_sport_facility_by_sport_club', this.globalService.getParams({
      id
    })).pipe(map(data => {
      return data;
    }));
  }*/

}
