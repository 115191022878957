<div class="position-relative">
  <ion-searchbar
    (ionClear)="clearSearchBar()"
    (ionFocus)="this.isSearchBarOpen = true;"
    (ionInput)="autocompleteSearch($event.detail.value)"
    [(ngModel)]="searchBarValue"
    class="text-start"
    debounce="275ms"
    placeholder="Dove vuoi allenarti?"
    showCancelButton="never"
    showClearButton="never"
    type="search"
  >
  </ion-searchbar>
  <button (click)="clearSearchBar()" *ngIf="isSearchBarOpen" @fadeIn class="button-main-search me-2"
          type="button">
    <ion-icon name="xmark"></ion-icon>
  </button>
  <div *ngIf="isSearchBarOpen" @fadeIn class="search-item-result rounded-bottom">
    <hr class="search-item-divider mb-0">
    <div class="row g-0">
      <div class="col-md-6 col-sm-12 pe-md-0">
        <ion-item (click)="onSearchClick()"
                  class="p-0 pointer item-selectable fit-rounded" color="none" lines="none">
          <ion-icon name="locate-outline" slot="start"></ion-icon>
          <ion-label class="bold">Qui vicino</ion-label>
        </ion-item>
      </div>
      <div class="col-md-6 col-sm-12 ps-md-0">
        <ion-item (click)="onSearchOnline()" class="p-0 pointer item-selectable fit-rounded" color="none" lines="none">
          <ion-icon name="radio-outline" slot="start"></ion-icon>
          <ion-label class="bold">Online</ion-label>
        </ion-item>
      </div>
    </div>
    <div class="mb-2">
      <ion-item (click)="searchPlace(city.place_id)" *ngFor="let city of autoCompleteCitiesArray"
                class="p-0 pointer item-selectable fit-rounded"
                color="none" lines="none">
        <ion-icon class="ion-color-primary me-3" name="location-dot" slot="start"></ion-icon>
        <ion-label class="bold text-wrap">{{city.description}}</ion-label>
      </ion-item>
      <ion-item (click)="onResultClick(sport_facility.id,'sport-facility')"
                *ngFor="let sport_facility of autoCompleteSportFacilityArray"
                class="p-0 pointer item-selectable fit-rounded"
                color="none"
                lines="none">
        <img [defaultImage]="defaultImage"
             [lazyLoad]="sport_facility?.image?.image" alt="Immagine {{sport_facility.name}}"
             class="me-3 object-fit-cover"
             height="30"
             src="" width="30">
        <ion-label class="bold text-wrap">
          {{sport_facility.name}}
          <small class="light">- Luogo sportivo</small>
        </ion-label>
      </ion-item>
      <ion-item (click)="onResultClick(sport_club.id,'sport-club')"
                *ngFor="let sport_club of autoCompleteSportClubArray" class="p-0 pointer item-selectable fit-rounded"
                color="none"
                lines="none">
        <img [defaultImage]="defaultImageSportClub"
             [lazyLoad]="sport_club?.image?.image" alt="Immagine {{sport_club.name}}" class="me-3 object-fit-cover"
             height="30"
             src="" width="30">
        <ion-label class="bold text-wrap">
          {{sport_club.name}}
          <small class="light">- Società sportiva o ASD</small>
        </ion-label>
      </ion-item>
    </div>
  </div>
</div>
