import {Component, OnInit} from '@angular/core';
import {SportFacilityModel} from "@app/models/sport-facility.model";
import {SportClubModel} from "@app/models/sport-club.model";
import {SportFacilityService} from "@app/services/sport-facility.service";
import {SportClubService} from "@app/pages/sport-club/sport-club.service";
import {FileService} from "@app/services/file.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommunicationService} from "@app/helper/communication.service";
import {NavigationRouteService} from "@app/helper/navigation-route.service";
import {fadeInAnimation} from "@app/animations";
import {ModalController} from "@ionic/angular";
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

@Component({
    selector: 'sport-searchbar-component',
    templateUrl: './sport-searchbar.component.html',
    styleUrls: ['./sport-searchbar.component.scss'],
    animations: [fadeInAnimation]
})
export class SportSearchbarComponent implements OnInit {

    autoCompleteCitiesArray: AutocompletePrediction[] = [];
    autoCompleteSportFacilityArray: SportFacilityModel[] = [];
    autoCompleteSportClubArray: SportClubModel[] = [];

    isSearchBarOpen: boolean = false;

    defaultImage: string = 'assets/img/default/sport_facility_sport.webp';
    defaultImageSportClub: string = 'assets/img/default/sport-club.webp';

    /* SEARCHBAR */
    public searchBarValue: string;

    constructor(
        private sportFacilityService: SportFacilityService,
        private sportClubService: SportClubService,
        private fileService: FileService,
        private router: Router,
        private route: ActivatedRoute,
        private communicationService: CommunicationService,
        public navigationRoute: NavigationRouteService,
        private modalCtrl: ModalController
    ) {
    }

    ngOnInit() {
    }

    autocompleteSearch(input) {
        this.autoCompleteCitiesArray = [];
        this.autoCompleteSportFacilityArray = [];
        this.autoCompleteSportClubArray = [];

        if (input) {
            this.getPredictionSuggestion(input).then((places: AutocompletePrediction[]) => {
                places.forEach((place) => {
                    if (this.autoCompleteCitiesArray.length < 3) {
                        this.autoCompleteCitiesArray.push(place)
                    }
                })
            })
            this.sportFacilityService.getAllSportFacilitySearchByName(input, 3).subscribe({
                next: sfArray => {
                    if (sfArray) {
                        sfArray.forEach(sf => {
                            this.fileService.getImage('thumbnail', 'sport-facility-cover', sf.id).subscribe({
                                next: image => {
                                    sf['image'] = image;
                                },
                                error: err => {
                                    console.error(err);
                                }
                            });
                            if (!this.autoCompleteSportFacilityArray.includes(sf)) {
                                this.autoCompleteSportFacilityArray.push(sf)
                            }
                        })
                    }
                }
            })
            this.sportClubService.getAllSportClubSearchByName(input, 3).subscribe({
                next: scArray => {
                    if (scArray) {
                        scArray.forEach(sc => {
                            this.fileService.getImage('thumbnail', 'sport-club-cover', sc.id).subscribe({
                                next: image => {
                                    sc['image'] = image;
                                },
                                error: err => {
                                    console.error(err);
                                }
                            });
                            if (!this.autoCompleteSportClubArray.includes(sc)) {
                                this.autoCompleteSportClubArray.push(sc)
                            }
                        })
                    }
                }
            })
        }
    }


    getPredictionSuggestion(input) {
        let predictionService = new google.maps.places.AutocompleteService();
        return new Promise(function (resolve, reject) {
            predictionService.getPlacePredictions({
                input: input,
                types: ['(cities)'],
                componentRestrictions: {country: 'IT'},
            }, function (place, status) {
                if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    resolve([])
                } else if (status != google.maps.places.PlacesServiceStatus.OK) {
                    reject(new Error(status));
                } else {
                    resolve(place);
                }
            }).then();
        });
    }

    searchPlace(place_id) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({placeId: place_id}).then(res => {
            if (res['results']?.length > 0) {
                const place = res['results'][0]
                const position = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }
                place.address_components.forEach(address => {
                    if (address.types[0] === "locality") {
                        this.navigationRoute.navigateSearchPlace(address.long_name)
                        if (location?.pathname !== '/') {
                            this.modalCtrl.dismiss().then()
                        }
                    } else if (address.types[0] === "administrative_area_level_3") {
                        this.navigationRoute.navigateSearchPlace(address.long_name)
                        if (location?.pathname !== '/') {
                            this.modalCtrl.dismiss().then()
                        }
                    }
                })
                /*this.router.navigate([`/ricerca/sport`], {
                  relativeTo: this.route,
                  queryParams: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    near: place.formatted_address,
                    page: 1
                  },
                  queryParamsHandling: ''
                }).then(() => {
                  this.clearSearchBar();
                });*/
            }
        })
    }

    openSearchBar() {
        this.isSearchBarOpen = true;
    }

    onSearchClick() {
        this.isSearchBarOpen = false;
        this.navigationRoute.navigate('search')
        if (location?.pathname !== '/') {
            this.modalCtrl.dismiss().then()
        }
    }

    onSearchOnline() {
        this.isSearchBarOpen = false;
        this.navigationRoute.navigate('search-online')
        if (location?.pathname !== '/') {
            this.modalCtrl.dismiss().then()
        }
    }

    setSearchBar(isOpen: boolean) {
        this.isSearchBarOpen = isOpen;
    }

    clearSearchBar() {
        this.isSearchBarOpen = false;
        this.searchBarValue = '';
    }

    requestUserPermission() {
        this.communicationService.customErrorToast('USER_DENIED_GEOLOCATION', 'warning').then(() => {
            this.isSearchBarOpen = false;
        })
    }

    onResultClick(id, type) {
        if (type === 'sport-facility') {
            this.navigationRoute.navigateSportFacility(id)
        } else if (type === 'sport-club') {
            this.navigationRoute.navigateSportClub(id)
        }
        this.clearSearchBar()
        if (location?.pathname !== '/') {
            this.modalCtrl.dismiss().then()
        }
    }

}
