import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {map, Observable, ReplaySubject} from 'rxjs';
import {API_URL} from '@environments/environment';
import {GlobalService} from "@app/services/global.service";
import {FileModel} from "@app/models/file.model";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }

  /*  upload(file, name, section): Observable<HttpEvent<any>> {
      const req = ('POST', API_URL + '/file', {
        file,
        name,
        section
      }, {
        reportProgress: true,
        responseType: "json"
      }).pipe(map(data => {
        console.log(data)
      }));
      return this.http.request(req);
    }*/

  fileToBase64(file: File): Observable<any> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  upload(file, section, id ?: number): Observable<HttpEvent<any>> {
    const type = 'image'
    const req = new HttpRequest('POST', API_URL + '/file', {
      file, section, type, id
    }, this.globalService.setTokenAndProgress());
    return this.http.request(req);
  }

  saveYouTubeLink(name, section, id): Observable<HttpEvent<any>> {
    const type = 'video'
    const req = new HttpRequest('POST', API_URL + '/file', {
      name, section, type, id
    }, this.globalService.setTokenAndProgress());
    return this.http.request(req);
  }

  getImage(size: string, section: string, id: number, logged_in?: boolean): Observable<any> {
    if (logged_in) {
      return this.http.get<any>(API_URL + '/get_image', this.globalService.setTokenAndParams({
        size,
        section,
        id
      })).pipe(map(data => {
        return data;
      }));
    } else {
      return this.http.get<any>(API_URL + '/get_image', this.globalService.setParams({
        size,
        section,
        id
      })).pipe(map(data => {
        return data;
      }));
    }
  }

  getVideo(section, id, logged_in?): Observable<FileModel[]> {
    if (logged_in) {
      return this.http.get<any>(API_URL + '/get_video', this.globalService.setTokenAndParams({
        section,
        id
      })).pipe(map((data: FileModel[]) => {
        return data;
      }));
    } else {
      return this.http.get<any>(API_URL + '/get_video', this.globalService.setParams({
        section,
        id
      })).pipe(map((data: FileModel[]) => {
        return data;
      }));
    }
  }

  deleteFile(id): Observable<any> {
    return this.http.delete<any>(API_URL + '/delete_file', this.globalService.setTokenAndParams({
      id
    })).pipe(map(data => {
      return data;
    }));
  }

  downloadFile(data: any, name: string = 'file') {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = name + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadSportClubStatistic(date_from?: string) {
    return this.http.get<any>(API_URL + '/download_all_statistic', this.globalService.setTokenAndParams({
      date_from
    })).pipe(map(data => {
      return data;
    }));
  }


}
