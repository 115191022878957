import {FederationModel} from "@app/models/federation.model";
import {ImageModel} from "@app/models/image.model";

export class SportClubModel {
  public id: number;
  public name: string;
  public address: string;
  public vat_number: string;
  public proprietor: string;
  public facebook_page: string;
  public show_website: boolean;
  public follower: number;
  public free_trial: boolean;
  public website: string;
  public operator_id: number;
  public country: string;
  public country_name: string;
  public state: string;
  public state_code: string;
  public city: string;
  public city_name: string;
  public check_up_score: number;
  public federation: FederationModel[];
  public enti: FederationModel[];
  public cap: number;
  public civic_number: string;
  public lat: string;
  public lng: string;
  public image: ImageModel;
  public deleted: boolean;

  constructor(data: any = []) {
    if (data) {
      for (const key in data) {
        this[key] = data[key] ?? null;
      }
    }
  }

}
